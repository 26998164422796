import "./App.css";
import Navbar from "./components/Navbar";
import LogoutMessage from "./components/LogoutMessage";
import Maingrid from "./components/Maingrid";
import Container from "@mui/material/Container";
import HelpDialog from "./components/HelpDialog";
import { useCallback, useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

// Import the functions you need from the SDKs you need
import { initializeApp } from "https://www.gstatic.com/firebasejs/10.12.2/firebase-app.js";
import { getAnalytics } from "https://www.gstatic.com/firebasejs/10.12.2/firebase-analytics.js";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  onAuthStateChanged,
  signOut,
  RecaptchaVerifier,
  getMultiFactorResolver,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
} from "https://www.gstatic.com/firebasejs/10.12.2/firebase-auth.js";
import {
  initializeAppCheck,
  ReCaptchaV3Provider,
  getToken,
} from "https://www.gstatic.com/firebasejs/10.12.2/firebase-app-check.js";
import {
  getFirestore,
  doc,
  setDoc,
  arrayUnion,
  arrayRemove,
  onSnapshot,
  deleteField,
} from "https://www.gstatic.com/firebasejs/10.12.2/firebase-firestore.js";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcdI5txUldaE7jEsp4oZ87o5CZcncfJ0c",
  authDomain: "teacher-dashboard-427618.firebaseapp.com",
  projectId: "teacher-dashboard-427618",
  storageBucket: "teacher-dashboard-427618.appspot.com",
  messagingSenderId: "16554155316",
  appId: "1:16554155316:web:8edbb983275117aaa61961",
  measurementId: "G-4Z0PW07M49",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const auth = getAuth(app);

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider("6LeWwwcqAAAAAHaCuT_Kt9wvorHhd-hzDNMpF0Vg"),

  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true,
});
let userObj;
let savedPhone;

function App() {
  const [loggedIn, setLoggedIn] = useState(false);
  const [helpOpen, setHelpOpen] = useState([false, "help"]);
  const [loginState, setLoginState] = useState(0);
  const [phoneNo, setPhoneNo] = useState("");
  const [errorHandle, setErrorHandle] = useState("");
  const [verificationIdentifier, setVerificationIdentifier] = useState("");
  const [getResolver, setResolver] = useState(null);
  const [phoneNumberEntry, setPhoneNumberEntry] = useState("");
  const [loginInitialized, setLoginInitialized] = useState("loading");
  const [customEmojiTag, setCustomEmojiTag] = useState({});
  const [tooltipPrevSet, setTooltipPrevSet] = useState(true);
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const changeStudentEmojiTag = async function (emojiTag, studentID) {
    const docRef = doc(db, "users", userObj.uid);
    if (
      customEmojiTag[studentID] &&
      customEmojiTag[studentID].includes(emojiTag)
    ) {
      setDoc(
        docRef,
        {
          emojiTags: {
            [studentID]:
              customEmojiTag[studentID].length == 1
                ? deleteField()
                : arrayRemove(emojiTag),
          },
        },
        { merge: true }
      )
        .then(() => {})
        .catch((error) => {});
    } else {
      setDoc(
        docRef,
        { emojiTags: { [studentID]: arrayUnion(emojiTag) } },
        { merge: true }
      )
        .then(() => {})
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const setTooltipStatus = async function () {
    const docRef = doc(db, "users", userObj.uid);
    setDoc(docRef, { tooltip: true }, { merge: true })
      .then(() => {})
      .catch((error) => {
        console.error(error);
      });
  };

  const sendMultiFactor = useCallback((resolver, stateOfUI) => {
    if (resolver.hints[0].factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions = {
        multiFactorHint: resolver.hints[0],
        session: resolver.session,
      };
      setPhoneNo(phoneInfoOptions.multiFactorHint.phoneNumber);
      const phoneAuthProvider = new PhoneAuthProvider(auth);

      // Ensure reCAPTCHA is solved before sending SMS
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptchaID", {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": () => {},
        });
      }
      stateOfUI != "noChange" && setErrorHandle("SMSPend");
      setLoginState(1);

      window.recaptchaVerifier
        .render()
        .then(() => {
          phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
            .then((verificationId) => {
              stateOfUI != "noChange" && setErrorHandle("SMSSuccess");
              setVerificationIdentifier(verificationId);
            })
            .catch((smsError) => {
              console.error("Error sending SMS: ", smsError);
              setErrorHandle("SMS");
            });
        })
        .catch((error) => {});
    }
  });

  const enrollMultifactor = useCallback((noChange) => {
    let user = userObj;
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, "recaptchaID", {
        size: "invisible",
        callback: (response) => {},
        "expired-callback": () => {},
      });
    }
    multiFactor(user)
      .getSession()
      .then(function (multiFactorSession) {
        let pendPhoneNo = !noChange ? phoneNumberEntry : savedPhone;
        const phoneInfoOptions = {
          phoneNumber: "+1" + pendPhoneNo,
          session: multiFactorSession,
        };

        const phoneAuthProvider = new PhoneAuthProvider(auth);
        // Send SMS verification code.
        !noChange && setErrorHandle("SMSPend");
        !noChange && setPhoneNo("+1" + phoneNumberEntry);
        return phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          window.recaptchaVerifier
        );
      })
      .then(function (verificationId) {
        // Save the session information.
        savedPhone = !noChange ? phoneNumberEntry : savedPhone;
        setPhoneNumberEntry("");
        setVerificationIdentifier(verificationId);
        setErrorHandle("SMSSuccessEnroll");
        setLoginState(1);
      })
      .catch((smsError) => {
        if (
          smsError.code == "auth/invalid-phone-number" ||
          smsError.code == "auth/operation-not-allowed"
        ) {
          setErrorHandle("SMSEnrollError");
          setPhoneNumberEntry("");
        } else {
          setErrorHandle("SMS");
        }
        console.error("Error sending SMS: ", smsError);
      });
  });

  const continueMultiFactor = useCallback((verificationId) => {
    const cred = PhoneAuthProvider.credential(
      verificationIdentifier,
      verificationId
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

    // Complete enrollment.
    multiFactor(userObj)
      .enroll(multiFactorAssertion, "phone")
      .then(() => {
        setLoggedIn(true);
        setLoginState(0);
        setPhoneNumberEntry("");
      })
      .catch((error) => {
        setErrorHandle("SMSInvalidCodeEnroll");
        setPhoneNumberEntry("");
      });
  });

  const login = useCallback(() => {
    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const token = credential.accessToken;
        // The signed-in user info.
        const user = result.user;
        // IdP data available using getAdditionalUserInfo(result)
        // ...

        userObj = user;

        setLoginState(1);
        setErrorHandle("SMSEnroll");
      })
      .catch((error) => {
        if (error.code == "auth/multi-factor-auth-required") {
          let resolver = getMultiFactorResolver(auth, error);
          setResolver(resolver);
          sendMultiFactor(resolver);
        }
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;

        console.error(errorCode + errorMessage);
        // The email of the user's account used.
        const email = error.customData.email;
        // The AuthCredential type that was used.
        const credential = GoogleAuthProvider.credentialFromError(error);
        // ...
      });
  }, []);

  const checkTwoFactor = useCallback((verificationCode) => {
    const cred = PhoneAuthProvider.credential(
      verificationIdentifier,
      verificationCode
    );
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    getResolver
      .resolveSignIn(multiFactorAssertion)
      .then(function (userCredential) {
        // userCredential will also contain the user, additionalUserInfo, optional
        // credential (null for email/password) associated with the first factor sign-in.
        // For example, if the user signed in with Google as a first factor,
        // userCredential.additionalUserInfo will contain data related to Google
        // provider that the user signed in with.
        // - user.credential contains the Google OAuth credential.
        // - user.credential.accessToken contains the Google OAuth access token.
        // - user.credential.idToken contains the Google OAuth ID token.
      })
      .catch(function (error) {
        // Error handling
        console.error(error);
        if (error.code == "auth/invalid-verification-code") {
          setErrorHandle("SMSInvalidCode");
          setPhoneNumberEntry("");
          sendMultiFactor(getResolver, "noChange");
        }
      });
  });

  const logout = useCallback(() => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        // Sign-out successful.
      })
      .catch((error) => {
        // An error happened.
      });
  }, []);

  let unsub = function () {};

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in, see docs for a list of available properties
        // https://firebase.google.com/docs/reference/js/auth.user
        const uid = user.uid;

        userObj = user;

        if (multiFactor(user).enrolledFactors.length > 0) {
          setLoginInitialized("loggedIn");
          setLoggedIn(true);
          setLoginState(0);
          setPhoneNumberEntry("");

          unsub = onSnapshot(doc(db, "users", userObj.uid), (doc) => {
            doc.data()?.emojiTags && setCustomEmojiTag(doc.data().emojiTags);

            setTooltipPrevSet(doc.data() && doc.data().tooltip);
          });
        }

        setLoginInitialized("loggedOut");

        // ...
      } else {
        unsub();
        // User is signed out
        // ...
        setLoginInitialized("loggedOut");
        setLoggedIn(false);
      }
    });

    return () => {
      unsub();
    };
  }, []);

  const getNewestToken = useCallback(() => {
    return new Promise((resolve, reject) => {
      userObj
        .getIdToken(true)
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
          setOpen(true);
        });
    });
  }, []);

  const getNewestAppCheckToken = useCallback(() => {
    return new Promise((resolve, reject) => {
      getToken(appCheck, false)
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }, []);

  return (
    <>
      <div id="recaptchaID"></div>
      <HelpDialog
        setHelpOpen={setHelpOpen}
        helpOpen={helpOpen}
        getAppCheckToken={getNewestAppCheckToken}
        refreshToken={getNewestToken}
      />
      <Navbar setHelpOpen={setHelpOpen} />
      <Container className="mainContainer" maxWidth="xl">
        {loggedIn ? (
          <Maingrid
            logoutFn={logout}
            getAppCheckToken={getNewestAppCheckToken}
            refreshToken={getNewestToken}
            setHelpOpen={setHelpOpen}
            changeStudentEmojiTag={changeStudentEmojiTag}
            customEmojiTag={customEmojiTag}
            setTooltipStatus={setTooltipStatus}
            tooltipPrevSet={tooltipPrevSet}
          />
        ) : (
          <>
            <LogoutMessage
              loginFn={login}
              loginState={loginState}
              setLoginState={setLoginState}
              phoneNo={phoneNo}
              errorHandle={errorHandle}
              checkTwoFactor={checkTwoFactor}
              phoneNumberEntry={phoneNumberEntry}
              setPhoneNumberEntry={setPhoneNumberEntry}
              enrollMultifactor={enrollMultifactor}
              continueMultiFactor={continueMultiFactor}
              loginInitialized={loginInitialized}
            />
          </>
        )}
      </Container>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Your session has expired. Please log in again.
        </Alert>
      </Snackbar>
    </>
  );
}

export default App;
