import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import { Icon, Typography } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
export default function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmoji = (event) => {
    props.changeStudentEmojiTag(event.target.innerText, props.studentID); //change emoji list
  };

  console.log(props.showTooltip);

  const [tooltipOpen, setTooltipOpen] = React.useState(
    (props.showTooltip ? true : false) && !props.tooltipPrevSet //make sure that showTooltip is true (index is 0) and that the tooltip hasn't been seen before
  );

  const handleTooltipClose = () => {
    setTooltipOpen(false);
    if (!props.tooltipPrevSet && typeof props.setTooltipStatus === "function") {
      props.setTooltipStatus(); //set that the tooltip has been seen
    }
  };

  return (
    <div className="smallMenu">
      <Tooltip
        arrow
        open={tooltipOpen}
        onClose={handleTooltipClose}
        onOpen={handleTooltipClose}
        title={`Click the three dots to add a custom tag to ${props.studentName}`}
        placement="right"
      >
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
      </Tooltip>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem
          disableRipple
          disableTouchRipple
          sx={{
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "transparent",
            },
            "&.Mui-selected": {
              backgroundColor: "transparent",
            },
            "&.Mui-selected:hover": {
              backgroundColor: "transparent",
            },
            flexDirection: "column",
          }}
        >
          <Typography
            variant="caption"
            sx={{ width: "100%", textAlign: "left", marginLeft: "12px" }}
          >
            Add Emoji Tag to {props.studentName}
          </Typography>
          <div>
            {[
              "🔴",
              "🟠",
              "🟡",
              "🟢",
              "🔵",
              "🟣",
              "🍎",
              "✏️",
              "🔔",
              "📚",
              "📎",
              "🧩",
              "⚠️",
              "🎭",
              "🥇",
              "🥈",
              "🥉",
              "🚩",
            ].map((emoji, index) => (
              <>
                {index !== 0 && index % 6 == 0 && <br />}
                <IconButton
                  key={emoji + index}
                  onClick={handleEmoji}
                  sx={
                    (props.customEmojiTag[props.studentID] &&
                      props.customEmojiTag[props.studentID].includes(emoji) && {
                        border: "2px dotted green",
                        margin: "1px",
                      }) || { margin: "1px" }
                  }
                  size={"small"}
                >
                  {emoji}
                </IconButton>
              </>
            ))}
          </div>
        </MenuItem>
        <MenuItem
          onClick={() => props.setHelpOpen([true, "opts", props.class])}
        >{`${props.class} Options`}</MenuItem>
        {props.parentEmails && (
          <MenuItem
            onClick={(event) => {
              navigator.clipboard.writeText(props.parentEmails);
              let button = event.currentTarget;
              button.innerHTML = "Copied!";

              setTimeout(() => {
                button.innerHTML = "Copy Parent Emails";
              }, 1000);
            }}
          >
            Copy Parent Emails
          </MenuItem>
        )}
        {props.studentEmail && (
          <MenuItem
            onClick={(event) => {
              navigator.clipboard.writeText(props.studentEmail);
              let button = event.currentTarget;
              button.innerHTML = "Copied!";

              setTimeout(() => {
                button.innerHTML = "Copy Student Email";
              }, 1000);
            }}
          >
            Copy Student Email
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
