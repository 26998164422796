import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Button, CardActions } from "@mui/material";
import Image from "./image.png";
import Skeleton from "@mui/material/Skeleton";
import { useEffect, useState, useContext, useMemo, Fragment } from "react";
import Chip from "@mui/material/Chip";
import StarIcon from "@mui/icons-material/Star";
import fallbackImage from "./fallback.jpg";
import SmallMenu from "./SmallMenu";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Picker from "emoji-picker-react";
import zIndex from "@mui/material/styles/zIndex";

export default function Usercard(props) {
  const [imageUrl, setImageUrl] = useState("#");

  let imageAlreadyExists = false;

  const recheckImage = function () {
    setImageUrl("#");
    if (props.imageDriveUrl == "") {
      setImageUrl(fallbackImage);
    } else {
      for (const q in props.imageBlobs) {
        if (props.studentID === props.imageBlobs[q]["studentId"]) {
          setImageUrl(props.imageBlobs[q]["blob"]);
          imageAlreadyExists = true;
        }
      }
    }
  };

  useEffect(() => {
    recheckImage();
  }, [props.imageBlobs, props.studentID, props.imageDriveUrl]);

  useEffect(() => {
    recheckImage();
    const abortController = new AbortController(); // Create an AbortController instance
    const signal = abortController.signal; // Get the signal from the AbortController

    // Define the fetchWithRetry function
    async function fetchWithRetry(url, options, retryCount = 4) {
      let lastError;

      for (let attempt = 0; attempt < retryCount; attempt++) {
        try {
          const response = await fetch(url, { ...options, signal }); // Pass the signal to the fetch options
          if (response.ok) {
            return response.blob(); // Return the blob if the response is ok
          } else {
            // If response is not ok, throw an error to catch it below
            throw new Error(
              `Network response was not ok (status: ${response.status})`
            );
          }
        } catch (error) {
          lastError = error;
          if (error.name !== "AbortError") {
            console.error(`Attempt ${attempt + 1} failed:`, error);
          }
          // Wait for a bit before retrying (optional)
          await new Promise((resolve) => setTimeout(resolve, 1000 * attempt));
        }
      }

      // If all attempts fail, throw the last error encountered
      throw lastError;
    }

    // Function to fetch image blob
    const fetchImageBlob = async (studentID, imageDriveUrl) => {
      let newToken = await props.refreshToken();
      let appCheckToken = await props.getAppCheckToken();

      const regex = /https:\/\/drive\.google\.com\/file\/d\/([a-zA-Z0-9_-]+)/;
      const match = imageDriveUrl.match(regex);
      const id = match ? match[1] : null;

      const blob = await fetchWithRetry(
        "https://teacher-dashboard-427618.nn.r.appspot.com/download-file",
        {
          method: "GET",
          headers: {
            "X-Authorization-Token": newToken,
            "Drive-Image-ID": id,
            "X-Firebase-AppCheck": appCheckToken.token,
          },
        }
      );

      const imageSrc = URL.createObjectURL(blob); // Create a URL for the Blob
      return { studentID, imageSrc };
    };

    // Fetch images concurrently
    if (!imageAlreadyExists) {
      (async () => {
        try {
          const imageBlob = await fetchImageBlob(
            props.studentID,
            props.imageDriveUrl
          );
          setImageUrl(imageBlob.imageSrc); // Set the image URL in state
          props.setNewImageBlob(imageBlob.studentID, imageBlob.imageSrc);
        } catch (error) {
          if (error.name !== "AbortError") {
            console.error(
              "There was a problem with your fetch operation:",
              error
            );
          }
        }
      })();
    }

    // Cleanup function to abort the fetch request if the component unmounts
    return () => {
      abortController.abort();
    };
  }, [props.studentID, props.imageDriveUrl]);

  const tags = props.tags.map((tag) => {
    if (tag[1])
      return (
        <Chip
          sx={{
            marginRight: "5px",
            marginTop: "5px",
            backgroundColor: tag[2] && tag[2],
            color: tag[3] && tag[3],
          }}
          label={tag[0]}
          key={tag[0]}
        />
      );
  });

  let currentFilteredIndex = 0;

  let phonesMap = [];

  let emailsMap = [];

  if (props.inSidebar) {
    // top index
    for (let i in props.filteredData) {
      if (props.filteredData[i][1] == props.studentID) {
        currentFilteredIndex = Number(i) + 1;
        break;
      }
    }

    // phone numbers

    let phonesByParent = props.phoneNumbers.split("\n");

    let primaryPhone = "";

    phonesMap = phonesByParent.map((phone, index) => {
      if (phone && phone.split(":").length > 2) {
        if (phone.split("[").length > 1) {
          primaryPhone = phone.split("[")[1].split("]")[0];
        }

        return (
          <div
            className="parentPhoneContainer"
            key={`${phone}-${index + Math.random(900)}`}
          >
            <Typography variant="subtitle1">{phone.split(":")[0]}</Typography>

            {phone
              .split(/(?<=^[^:]*):/)[1]
              .split(";")
              .map((num, index) => {
                return (
                  <Button
                    key={num + index}
                    variant={
                      primaryPhone == num.split(":")[0].replace(/\s/g, "")
                        ? "contained"
                        : "outlined"
                    }
                    color="primary"
                    sx={{ width: "100%", marginBottom: "10px" }}
                    onClick={() =>
                      window.open(`tel:${num.split(":")[1].split("[")[0]}`)
                    }
                  >
                    {num.split("[")[0]}
                  </Button>
                );
              })}
          </div>
        );
      }
    });

    emailsMap = props.parentEmails.split(",").map((email, index) => {
      if (email.includes("@")) {
        return (
          <Fragment key={email + index}>
            <Typography
              variant="subtitle1"
              sx={{ marginLeft: "0px !important" }}
            >
              {email}
            </Typography>

            <CardActions
              sx={{
                display: "flex",
                width: "100%",
                marginLeft: "0px !important",
                padding: 0,
              }}
              key={email + index + "copya"}
            >
              <Button
                key={email + index + "copy"}
                variant="outlined"
                color="primary"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  marginRight: "4px !important",
                }}
                onClick={(event) => {
                  navigator.clipboard.writeText(email);
                  let button = event.currentTarget;
                  button.innerHTML = "Copied!";

                  setTimeout(() => {
                    button.innerHTML = "Copy";
                  }, 1000);
                }}
              >
                Copy
              </Button>

              <Button
                key={email + index}
                variant="outlined"
                color="primary"
                sx={{
                  width: "100%",
                  marginBottom: "10px",
                  marginLeft: "4px !important",
                }}
                onClick={() => window.open(`mailto:${email}`)}
              >
                Send Email
              </Button>
            </CardActions>
          </Fragment>
        );
      }
    });
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "rgba(0, 0, 0, 0)",
      color: "rgba(0, 0, 0, 0.87)",
    },
  }));

  return (
    <Card
      sx={
        props.inSidebar
          ? {
              maxWidth: "100%",
              marginRight: "30px",
              maxHeight: "100%",
              overflowY: "auto",
            }
          : { maxWidth: "100%", height: "100%" }
      }
      elevation={props.inSidebar && 3}
      className="userCard"
    >
      {!props.inSidebar && (
        <SmallMenu
          class={props.studentClass}
          setHelpOpen={props.setHelpOpen}
          parentEmails={props.parentEmails
            .split(",")
            .filter((x) => x.includes("@"))}
          studentEmail={props.studentEmail}
          studentName={props.studentName}
          studentID={props.studentID}
          changeStudentEmojiTag={props.changeStudentEmojiTag}
          customEmojiTag={props.customEmojiTag}
          showTooltip={props.itemIndex == 0}
          setTooltipStatus={props.setTooltipStatus}
          tooltipPrevSet={props.tooltipPrevSet}
        />
      )}
      {props.inSidebar && (
        <CardActions className="cardHeaderActions">
          <Button
            size="small"
            color="primary"
            onClick={() => props.newStudent()}
          >
            Return
          </Button>

          <IconButton
            size="small"
            aria-label="previous"
            onClick={() => {
              props.newStudent(props.filteredData[currentFilteredIndex - 2][1]);
            }}
            disabled={props.studentID == props.filteredData[0][1]}
          >
            <ChevronLeftIcon />
          </IconButton>
          <IconButton
            size="small"
            aria-label="next"
            onClick={() => {
              props.newStudent(props.filteredData[currentFilteredIndex][1]);
            }}
            disabled={
              props.studentID ==
              props.filteredData[props.filteredData.length - 1][1]
            }
          >
            <ChevronRightIcon />
          </IconButton>
          <Typography variant="p " className="centerText">
            {`${currentFilteredIndex}/${props.filteredData.length}`}
          </Typography>
          <SmallMenu
            class={props.studentClass}
            setHelpOpen={props.setHelpOpen}
            parentEmails={props.parentEmails
              .split(",")
              .filter((x) => x.includes("@"))}
            studentEmail={props.studentEmail}
            studentName={props.studentName}
            studentID={props.studentID}
            customEmojiTag={props.customEmojiTag}
            changeStudentEmojiTag={props.changeStudentEmojiTag}
          />
        </CardActions>
      )}

      <CardActionArea
        sx={{ height: "100%" }}
        onClick={() => props.newStudent(props.studentID)}
        disabled={props.inSidebar}
      >
        <div className="TagsTop">
          {props.customEmojiTag[props.studentID] &&
            props.customEmojiTag[props.studentID].map((emoji, index) => (
              <Chip
                key={emoji + index + 1}
                label={emoji}
                variant="outlined"
                sx={{ margin: "5px", backgroundColor: "rgb(255,255,255)" }}
              />
            ))}
          {props.isNewToBialik && (
            <Chip
              icon={<StarIcon />}
              sx={{
                margin: "5px",
                backgroundColor: "rgba(255, 209, 5, 0.5)",
              }}
              label="New to Bialik"
            />
          )}
          {props.photoReleaseAllowed && (
            <Chip
              icon={<NoPhotographyIcon />}
              sx={{
                margin: "5px",
                backgroundColor: "rgba(255, 5, 5, 0.5)",
              }}
              label="No Photo Release"
            />
          )}
        </div>

        {imageUrl != "#" ? (
          <CardMedia
            component="img"
            height={props.inSidebar ? "300" : "270"}
            image={imageUrl}
            alt={props.studentName}
          ></CardMedia>
        ) : (
          <Skeleton
            sx={{ height: 270 }}
            animation="wave"
            variant="rectangular"
          />
        )}

        <CardContent sx={{ minHeight: "100%" }}>
          <Typography variant="h5" component="div">
            {props.studentName}
          </Typography>
          <Typography gutterBottom variant="subtitle1" color="text.secondary">
            {props.hebrewName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Class: {props.studentClass}
          </Typography>
          {!props.inSidebar && (
            <>
              <Typography variant="body2" color="text.secondary">
                {props.jsAvg &&
                  `${props.selectedTerm}: ${props.jsAvg.toFixed(2)}`}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Birthdate:{" "}
                {(() => {
                  const [year, month, day] = props.birthdate.split("-");
                  const date = new Date(year, month - 1, day, 12); // Set time to noon to avoid timezone issues
                  return date.toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  });
                })()}
              </Typography>
            </>
          )}

          {!tags.every((v) => v == null) && (
            <div className="tagsDiv">{tags}</div>
          )}
        </CardContent>
      </CardActionArea>
      <div className="cardButtons">
        {props.inSidebar && (props.studentEmail || props.parentEmails) && (
          <>
            <Typography variant="h6" sx={{ width: "100%", marginLeft: "10px" }}>
              Email Contacts
            </Typography>

            <CardActions sx={{ display: "flex" }}>
              {props.studentEmail && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={() => window.open(`mailto:${props.studentEmail}`)}
                >
                  Student
                </Button>
              )}
              {props.parentEmails && (
                <Button
                  variant="outlined"
                  color="primary"
                  sx={{ width: "100%" }}
                  onClick={() =>
                    window.open(
                      `mailto:${props.parentEmails
                        .split(",")
                        .filter((x) => x.includes("@"))}`
                    )
                  }
                >
                  All Parents
                </Button>
              )}
            </CardActions>
            <CardActions sx={{ display: "flex", flexWrap: "wrap" }}>
              {emailsMap}
            </CardActions>
          </>
        )}
        {props.inSidebar && props.phoneNumbers != "" && (
          <>
            <Typography variant="h6" sx={{ width: "100%", marginLeft: "10px" }}>
              Phone Contacts
            </Typography>
            <CardActions sx={{ display: "flex", flexWrap: "wrap" }}>
              {phonesMap}
            </CardActions>
          </>
        )}
      </div>
    </Card>
  );
}
