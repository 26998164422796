import { Typography } from "@mui/material";
import "../App.css";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import GoogleIcon from "@mui/icons-material/Google";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import { useState } from "react";

export default function Hero(props) {
  let phoneNumberEntry = props.phoneNumberEntry;
  let setPhoneNumberEntry = props.setPhoneNumberEntry;

  return (
    <Paper
      elevation={3}
      className="welcomeBox"
      sx={{ position: "relative", maxWidth: "700px" }}
    >
      {props.loginState == 0 && (
        <>
          <Typography variant="h4" gutterBottom>
            Welcome!
          </Typography>
          <Typography className="mainSubtitle" variant="subtitle1" gutterBottom>
            Sign in with your Bialik Google account to get started.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            disabled={props.loginInitialized != "loggedOut"}
            onClick={props.loginFn}
          >
            {props.loginInitialized == "loggedOut" ? (
              <>
                <GoogleIcon className="googleLoginIcon" /> Sign In with Google
              </>
            ) : (
              <>
                <HourglassBottomIcon className="HourglassBottomIcon" />{" "}
                Loading...
              </>
            )}
          </Button>
        </>
      )}
      {props.loginState == 1 && (
        <>
          <Typography variant="h6" gutterBottom>
            Two Step Verification
          </Typography>
          <Typography className="mainSubtitle" variant="subtitle1" gutterBottom>
            {props.errorHandle == "SMSPend" &&
              `We are sending an SMS message to your phone ${props.phoneNo}...`}
            {(props.errorHandle.includes("SMSSuccess") ||
              props.errorHandle.includes("SMSInvalidCode")) &&
              `An SMS message has been sent to your phone ${props.phoneNo}. Please enter the code below.`}
            {props.errorHandle == "SMS" &&
              `We're having trouble sending you an SMS message. Please try again later, or email it@bialik.ca for help.`}
            {props.errorHandle.includes("SMSEnroll") &&
              "For added security, we now require all users to enroll a second authentication factor. Please enter your phone number below to continue.  A verification code will be sent to you via SMS for confirmation."}
          </Typography>
          {(props.errorHandle.includes("SMSSuccess") ||
            props.errorHandle.includes("SMSInvalidCode") ||
            props.errorHandle.includes("SMSEnroll")) && (
            <>
              <TextField
                error={
                  props.errorHandle.includes("SMSInvalidCode") ||
                  props.errorHandle == "SMSEnrollError"
                }
                helperText={
                  (props.errorHandle == "SMSInvalidCode" &&
                    "Invalid code. A new code has been sent to your phone.") ||
                  (props.errorHandle == "SMSEnrollError" &&
                    "Invalid phone number. Please try again.") ||
                  (props.errorHandle == "SMSInvalidCodeEnroll" &&
                    "Invalid code. Please try again.")
                }
                id="outlined-basic"
                label={
                  props.errorHandle.includes("SMSEnroll")
                    ? "Phone Number"
                    : "Verification Code"
                }
                variant="outlined"
                onChange={(event) => {
                  const value = event.target.value;
                  if (
                    /^\d*$/.test(value) &&
                    value.length <=
                      (props.errorHandle.includes("SMSEnroll") ? 10 : 6)
                  ) {
                    setPhoneNumberEntry(value);
                  }
                }}
                value={phoneNumberEntry}
                size="small"
                sx={{ marginRight: "10px" }}
                name="studentName"
                InputProps={{
                  startAdornment: props.errorHandle.includes("SMSEnroll") &&
                    props.errorHandle.includes("SMSEnroll") && (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                }}
              />
            </>
          )}

          {(props.errorHandle == "SMS" ||
            props.errorHandle.includes("SMSSuccess") ||
            props.errorHandle.includes("SMSInvalidCode") ||
            props.errorHandle.includes("SMSEnroll")) && (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  props.errorHandle == "SMS" && props.setLoginState(0);
                  (props.errorHandle == "SMSSuccess" ||
                    props.errorHandle == "SMSInvalidCode") &&
                    props.checkTwoFactor(phoneNumberEntry);

                  props.errorHandle.includes("SMSEnroll") &&
                    props.enrollMultifactor();
                  props.errorHandle == "SMSSuccessEnroll" &&
                    props.continueMultiFactor(phoneNumberEntry);

                  props.errorHandle == "SMSInvalidCodeEnroll" &&
                    props.continueMultiFactor(phoneNumberEntry);
                }}
              >
                {(props.errorHandle.includes("SMSSuccess") ||
                  props.errorHandle.includes("SMSInvalidCode")) &&
                  "Continue"}
                {props.errorHandle == "SMS" && "Try Again"}
                {props.errorHandle.includes("SMSEnroll") && "Send Code"}
              </Button>
            </>
          )}
        </>
      )}
    </Paper>
  );
}
